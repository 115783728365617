import { getClient } from 'utils/utils';
import demoConfig from './demoConfig';
import dollarGeneralConfig from './dollarGeneral';
import dollarGeneralSupportConfig from './dollarGeneralSupportConfig';
import emboldConfig from './emboldConfig';
import emsanaCareConfig from './emsanaCareConfig';
import icubaConfig from './icubaConfig';
import microsoftConfig from './microsoftConfig';
import polkConfig from './polkConfig';
import surgeryPlusConfig from './surgeryPlusConfig';
import walmartConfig from './walmartConfig';
import bnsfConfig from './bnsfConfig';
import jbhuntConfig from './jbhuntConfig';
import soctConfig from './soctConfig';
import clrConfig from './clrConfig';
import customerDemoConfig from './customerDemoConfig';
import soctProviderLookupConfig from './soctProviderLookupConfig';
import quantumConfig from './quantumConfig';
import ensembleConfig from './ensembleConfig';
import ensembleProviderLookupConfig from './ensembleProviderLookupConfig';
import walmartNationalConfig from './walmartNationalConfig';
import convivaConfig from './convivaConfig';

// set this value with localStorage OR default to walmart
const LOCAL_CLIENT_TO_USE = localStorage.getItem('localClientToUse') || 'walmart'; // Change this value to set client config locally

export default function getClientConfig() {
  let client = getClient();
  if (client === 'local') {
    if (window.location.href.includes('/override')) {
      client = 'walmart';
      localStorage.clear();
      localStorage.setItem('localClientToUse', 'walmart');
      window.location.href = '/';
    }
    client = LOCAL_CLIENT_TO_USE;
  }

  switch (client) {
    case 'walmart':
      return walmartConfig;
    case 'walmart-national':
      return walmartNationalConfig;
    case 'microsoft':
      return microsoftConfig;
    case 'polk':
      return polkConfig;
    case 'demo':
      return demoConfig;
    case 'customerdemo':
      return customerDemoConfig;
    case 'embold':
      return emboldConfig;
    case 'icuba':
      return icubaConfig;
    case 'bcbst-support':
      return dollarGeneralSupportConfig;
    case 'bcbst':
      return dollarGeneralConfig;
    case 'emsanacare':
      return emsanaCareConfig;
    case 'surgeryplus':
      return surgeryPlusConfig;
    case 'bnsf':
      return bnsfConfig;
    case 'jbhunt':
      return jbhuntConfig;
    case 'carecompass':
      return soctConfig;
    case 'carecompass-providerlookup':
      return soctProviderLookupConfig;
    case 'continental':
      return clrConfig;
    case 'ensemblehp':
      return ensembleConfig;
    case 'ensemblehp-providerlookup':
      return ensembleProviderLookupConfig;
    case 'quantum':
      return quantumConfig;
    case 'conviva':
      return convivaConfig;
    case 'feature-three':
      return demoConfig;
    case 'feature-two':
      return microsoftConfig;
    case 'feature-one':
      return demoConfig;
    default:
      console.error('Unhandled client configuration case');
      return demoConfig;
  }
}
