import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, ListSubheader, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Autocomplete, Skeleton } from '@material-ui/lab';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import {
  CARE_CATEGORIES_MAP,
  CARE_CATEGORY_OPTIONS,
  PLACE_RESULT_TYPE,
  PROVIDER_RESULT_TYPE,
} from 'utils/constants';
import { MIN_SEARCH_STRING } from 'store/slices/search/searchConstants';
import useFocusAnchors from 'utils/FocusRefContext';
import { logDevMessage, sortByGroup } from 'utils/utils';
import { select, actions } from 'store/toolkit';
import { defaultPlaceOptions, defaultProviderOptions } from './defaultOptions';
import useFetchAutocompleteSuggestions from './useFetchAutocompleteSuggestions';
import highlightSearchTerms from './highlightSearchTerms';

const SEARCH_TYPE_OPTION_CLASS = 'search-type-option';
const FREEFORM_OPTION_CLASS = 'freeform-option';
const CLEAR_BTN_CLASS = 'custom-clear-btn';

export const MULTI_SEARCH_PLACEHOLDER_TEXT = 'What are you looking for?';

const useStyles = makeStyles((theme) => ({
  // https://v4.mui.com/api/autocomplete/#css
  inputRoot: {
    borderRadius: (props) => props.borderRadius || 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: (props) => props.borderRadius || 0,
      borderColor: theme.palette.grey[300],
    },
    '& > svg:first-child': {
      marginLeft: theme.spacing(1),
    },
    [`& .${CLEAR_BTN_CLASS}`]: {
      padding: 0,
    },
  },
  groupLabel: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  option: {
    color: theme.palette.primary.main,
    minHeight: 'auto',
    '&[data-focus="true"]': {
      borderRadius: theme.shape.borderRadius * 2,
      border: `1px solid ${theme.palette.focusIndicator}`,
    },
  },
  popper: {
    zIndex: theme.zIndex.appBar - 1,
  },
  paper: (props) => ({
    marginTop: props.mobileView ? 12 : undefined,
    boxShadow: props.mobileView ? 'none' : undefined,
    borderRadius: props.mobileView ? 0 : undefined,
  }),
  listbox: (props) => ({
    maxHeight: props.mobileView ? 'calc(100vh - 180px)' : '45vh',
    padding: props.mobileView ? 0 : theme.spacing(1),

    // select the li that contains the search type group
    [`& > li:has(.${SEARCH_TYPE_OPTION_CLASS})`]: {
      '& .MuiAutocomplete-groupLabel': {
        // hide the group header for the provider/place options
        display: 'none',
      },
      '& .MuiAutocomplete-groupUl': {
        // arrange the Provider/place options side by side
        display: 'flex',
        columnGap: theme.spacing(1),
        padding: theme.spacing(1),
        '& > .MuiAutocomplete-option': {
          // make the provider/place options look like buttons
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          borderRadius: theme.shape.borderRadius * 6,
          padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
        },
        [`& .MuiAutocomplete-option[aria-selected="true"]`]: {
          // make the selected search type option colored like a contained primary button
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        '& > .MuiAutocomplete-option[data-focus="true"]': {
          // add special data focus indicator for keyboard users
          outline: `2px solid ${theme.palette.focusIndicator}`,
        },
      },
    },

    // select the specific free form search option
    [`& > li:has(.${FREEFORM_OPTION_CLASS})`]: {
      '& .MuiAutocomplete-groupLabel': {
        display: 'none',
      },

      '& .MuiAutocomplete-option': {
        // free form search option
        color: theme.palette.secondary.main,
        marginTop: theme.spacing(1),
        ...theme.typography.button,
      },
    },
  }),
}));

export const FREEFORM_GROUP_NAME = 'freeform-group';
export const SEARCH_TYPE_GROUP_NAME = 'search-type';

const SEARCH_TYPE_LABEL = {
  provider: 'Provider',
  place: 'Facility',
};

const SEARCH_TYPE_OPTIONS = [
  {
    group: SEARCH_TYPE_GROUP_NAME,
    name: SEARCH_TYPE_LABEL.provider,
    value: PROVIDER_RESULT_TYPE,
    isSearchTypeOption: true,
  },
  {
    group: SEARCH_TYPE_GROUP_NAME,
    name: SEARCH_TYPE_LABEL.place,
    value: PLACE_RESULT_TYPE,
    isSearchTypeOption: true,
  },
];

export const PROVIDER_SEARCH_GROUP_ORDER = [
  SEARCH_TYPE_GROUP_NAME,
  ...CARE_CATEGORIES_MAP[PROVIDER_RESULT_TYPE],
  FREEFORM_GROUP_NAME,
];

export const PLACE_SEARCH_GROUP_ORDER = [
  SEARCH_TYPE_GROUP_NAME,
  ...CARE_CATEGORIES_MAP[PLACE_RESULT_TYPE],
  FREEFORM_GROUP_NAME,
];

export default function MultiAutocomplete({
  classProps,
  TextFieldProps,
  mobileView,
  forceOpen,
  onSubmit,
  ...props
}) {
  const classes = useStyles({ ...classProps, ...props, mobileView });

  const focusAnchors = useFocusAnchors();

  const dispatch = useDispatch();
  const handleFetchSuggestions = useFetchAutocompleteSuggestions();

  const suggestionCategoriesFetching = useSelector(select.search.suggestionCategoriesFetching);
  const autocompleteFetchingMap = useSelector(select.search.autocompleteFetchingMap);
  const fetchedSuggestions = useSelector(select.search.autocompleteSuggestions);
  const searchText = useSelector(select.search.text);
  const autocompleteType = useSelector(select.search.autocompleteType);
  const autocompleteValue = useSelector(select.search.autocompleteValue);
  const isReadyForSearch = useSelector(select.search.isReadyForSearch);

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleFocus = useCallback(() => {
    // refetch suggestions when search bar is re-opened
    handleFetchSuggestions({ searchText, autocompleteType });
  }, [autocompleteType, handleFetchSuggestions, searchText]);

  const handleClear = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(actions.search.autocompleteCleared());
      // give RTK one frame set state before re-focusing
      setOpen(true);
      setTimeout(() => focusAnchors.handleFocus(focusAnchors.searchInput), 0);
    },
    [dispatch, focusAnchors]
  );

  const handleTextInput = useCallback(
    (evt, value, reason) => {
      // when the reason is 'reset', let the handleSelected function take care of changing the text state
      if (reason !== 'input') return;
      dispatch(actions.search.handleTextInput({ value, reason }));
      handleFetchSuggestions({ searchText: value, autocompleteType });
    },
    [autocompleteType, dispatch, handleFetchSuggestions]
  );

  const handleSearchTypeSelected = useCallback(
    (evt, value, reason) => {
      if (!value.isSearchTypeOption) {
        logDevMessage('handleSearchTypeSelected was triggered on a non-search-type option');
        return;
      }

      if (value.value !== autocompleteType) {
        // the type is being changed from provider to place or place to provider
        dispatch(actions.search.changeAutocompleteType({ value, reason }));
        if (searchText.length)
          handleFetchSuggestions({ searchText, autocompleteType: value.value });
        return;
      }

      if (isReadyForSearch) {
        // when input is typed an enter is pressed while the input is focused, by default the first option is "selected"
        // however the user is likely intending on submitting the search in this case
        onSubmit();
        handleClose();
      }
    },
    [
      autocompleteType,
      isReadyForSearch,
      dispatch,
      searchText,
      handleFetchSuggestions,
      onSubmit,
      handleClose,
    ]
  );

  const handleSelected = useCallback(
    (evt, value, reason) => {
      if (value?.isSearchTypeOption) {
        // either the provider or place option was selected
        handleSearchTypeSelected(evt, value, reason);
        return;
      }

      dispatch(actions.search.suggestionSelected({ value, reason }));
      handleClose();
      onSubmit();
    },
    [dispatch, handleClose, onSubmit, handleSearchTypeSelected]
  );

  const renderOption = useCallback(
    (opt) => {
      if (opt.group === SEARCH_TYPE_GROUP_NAME) {
        // apply classes to the provider/facility options so that css can style them like buttons
        return (
          <>
            <Typography variant="srOnly">Show {opt.name} search options</Typography>
            <span className={SEARCH_TYPE_OPTION_CLASS} aria-hidden="true">
              {opt.name}
            </span>
          </>
        );
      }

      if (opt.isFreeformOption) {
        // add class to the freeform option to add special styling
        return <span className={FREEFORM_OPTION_CLASS}>{opt.name}</span>;
      }

      // placeholder option to ensure all groups are visible during loading state
      if (opt.isFetchingFlag) return null;

      // recommended searches
      if (!searchText) return opt.name;

      // /autocomplete endpoint suggestions
      return highlightSearchTerms(searchText, opt.name);
    },
    [searchText]
  );

  /**
   * This renderGroup is copied from https://github.com/mui/material-ui/blob/v4.x/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L370
   *
   * The only modification is adding in the Icon and manually defining the className
   * */
  const renderGroup = useCallback(
    (params) => {
      const categoryIsFetching = autocompleteFetchingMap[params.group];
      const careCategoryOption = CARE_CATEGORY_OPTIONS[params.group];
      return (
        <li key={params.key}>
          <ListSubheader
            className={`MuiAutocomplete-groupLabel ${classes.groupLabel}`}
            component="div"
          >
            {careCategoryOption?.IconComponent && (
              <careCategoryOption.IconComponent fontSize="medium" color="inherit" />
            )}
            {careCategoryOption?.label || params.group}
          </ListSubheader>

          {categoryIsFetching ? (
            <Skeleton height={40} style={{ marginTop: -15, zIndex: 1 }} />
          ) : (
            <ul className="MuiAutocomplete-groupUl">{params.children}</ul>
          )}
        </li>
      );
    },
    [autocompleteFetchingMap, classes.groupLabel]
  );

  /**
   * This function is responsible for determining which options are considered selected.
   *
   * The selected state of the options has a11y and styling implications
   */
  const getOptionSelected = useCallback(
    (option, currentValue) => {
      if (option.isSearchTypeOption) {
        // this is necessary so that the currently selected search type receives the correct aria-selected value
        return option.value === autocompleteType;
      }

      return option.group === currentValue.group && option.name === currentValue.name;
    },
    [autocompleteType]
  );

  const defaultSuggestions = useMemo(
    () => (autocompleteType === PLACE_RESULT_TYPE ? defaultPlaceOptions : defaultProviderOptions),
    [autocompleteType]
  );

  const groupOrder = useMemo(
    () =>
      autocompleteType === PLACE_RESULT_TYPE
        ? PLACE_SEARCH_GROUP_ORDER
        : PROVIDER_SEARCH_GROUP_ORDER,
    [autocompleteType]
  );

  const combinedOptions = useMemo(() => {
    const isSearchableString = searchText.trim().length >= MIN_SEARCH_STRING;
    const allOptions = [...SEARCH_TYPE_OPTIONS];
    if (fetchedSuggestions.length) {
      allOptions.push(...fetchedSuggestions);
    } else if (!isSearchableString) {
      allOptions.push(...defaultSuggestions);
    } else {
      // no results
    }

    if (isSearchableString) {
      // when there is searchText in the input, add freeform option to the end of the options list
      allOptions.push({
        group: FREEFORM_GROUP_NAME,
        name: `See all ${
          autocompleteType === PROVIDER_RESULT_TYPE
            ? SEARCH_TYPE_LABEL.provider
            : SEARCH_TYPE_LABEL.place
        } results for "${searchText}"`,
        isFreeformOption: true,
      });
    }

    // add dummy "options" for groups loading suggestions to ensure group is rendered with loading state
    if (suggestionCategoriesFetching.length) {
      suggestionCategoriesFetching.forEach((category) =>
        allOptions.push({ group: category, isFetchingFlag: true })
      );
    }

    return sortByGroup(allOptions, groupOrder);
  }, [
    autocompleteType,
    defaultSuggestions,
    fetchedSuggestions,
    groupOrder,
    searchText,
    suggestionCategoriesFetching,
  ]);

  const showClearButton = useMemo(() => searchText.length > 0, [searchText]);

  const clearButton = useMemo(() => {
    if (!showClearButton) return undefined;

    return (
      <IconButton aria-label="Clear" onClick={handleClear} size="small" className={CLEAR_BTN_CLASS}>
        <CloseIcon />
      </IconButton>
    );
  }, [handleClear, showClearButton]);

  return (
    <Autocomplete
      // id for aria attributes
      id="multi-autocomplete"
      // controlled states
      inputValue={searchText}
      onInputChange={handleTextInput}
      value={autocompleteValue}
      onChange={handleSelected}
      open={open || forceOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onFocus={handleFocus}
      // interaction
      disableCloseOnSelect // we are manually controlling the opening and closing with the handleSelect function
      freeSolo
      openOnFocus
      // options
      options={combinedOptions}
      filterOptions={(x) => x} // filtering options is handled by the backend
      groupBy={(opt) => opt.group}
      getOptionLabel={(opt) => opt.name || ''}
      // a11y
      getOptionSelected={getOptionSelected}
      // rendering
      disableClearable // disable the default clearable button in favor of our own clearButton
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          aria-label={MULTI_SEARCH_PLACEHOLDER_TEXT}
          placeholder={MULTI_SEARCH_PLACEHOLDER_TEXT}
          inputRef={focusAnchors.searchInput}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon color="primary" />,
            endAdornment: clearButton,
            autoFocus: mobileView,
          }}
          {...TextFieldProps}
        />
      )}
      renderOption={renderOption}
      renderGroup={renderGroup}
      disablePortal={mobileView}
      // styles
      classes={classes}
      {...props}
    />
  );
}

/** Extends MUI Autocomplete - https://v4.mui.com/api/autocomplete/#props */
MultiAutocomplete.propTypes = {
  classProps: PropTypes.shape({
    borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  TextFieldProps: PropTypes.shape({}), // https://v4.mui.com/api/text-field/#props
  mobileView: PropTypes.bool,
  forceOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
};

MultiAutocomplete.defaultProps = {
  classProps: {},
  TextFieldProps: {},
  mobileView: false,
  forceOpen: false,
  onSubmit: () => {},
};
