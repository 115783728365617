import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Button } from '@material-ui/core';

import { select, actions } from 'store/toolkit';
import SearchForm from 'components/ModernExperience/Search/SearchForm';
import LeftArrowIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles(() => ({
  banner: {
    position: 'relative',
    minHeight: 60,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bannerHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    lineHeight: '1.5rem',
  },
  goBackButton: {
    position: 'absolute',
    paddingLeft: 0,
    left: 0,
  },
  '@media (forced-colors: active) and (prefers-color-scheme: dark)': {
    goBackButton: {
      '& svg': {
        fill: 'white',
      },
    },
  },
}));

function MobileSearchPage() {
  const classes = useStyles();

  const [cachedSearchSlice] = useState(useSelector(select.search.slice)); // cache the entire searchSlice on page mount
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBack = () => {
    // if back was pressed, then no search was performed, and we want to return the searchSlice to it's previous cached state
    dispatch(actions.search.overwriteSlice(cachedSearchSlice));
    history.goBack();
  };

  return (
    <div style={{ background: 'white', paddingBottom: 40 }}>
      <Container
        component="section"
        maxWidth="md"
        id="mobile-search-update"
        className={`${classes.banner} banner`}
      >
        <Button
          onClick={handleBack}
          className={classes.goBackButton}
          color="primary"
          startIcon={<LeftArrowIcon />}
        >
          Back
        </Button>
        <Typography
          color="primary"
          component="div"
          className={`${classes.bannerHeader} banner-header`}
        >
          Update Search
        </Typography>
      </Container>
      <Container maxWidth="md" id="mobile-landing-container" style={{ margin: '1.5rem auto' }}>
        <SearchForm mobileView showClearAll autoSearch />
      </Container>
    </div>
  );
}

export default MobileSearchPage;
