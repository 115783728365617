import { createSlice } from '@reduxjs/toolkit';

import { logDevMessage } from 'utils/utils';
import {
  DEFAULT_RADIUS,
  CARE_CATEGORIES,
  PLACE_RESULT_TYPE,
  DEFAULT_PROVIDER_SORT,
  DEFAULT_PLACE_SORT,
} from 'utils/constants';
import * as chatActions from 'store/slices/chat/chatSlice';
import { startOver, updateStoreFromUrl } from 'store/appActions';
import {
  BOOL_FILTER_TYPE,
  ARRAY_FILTER_TYPE,
  VALID_RADIUS_VALUES,
  MAX_RADIUS_VALUE,
} from './filterConstants';
import { FILTERS_SLICE_NAME } from '../slicesNames';
import { fetchPredictedResultCount, fetchFilterOptions } from './filterThunks';
import { findSmallestRadius } from '../chat/chatUtils';
import { isValidOrderingParam } from './filterUtils';

const initialState = {
  options: {
    isLoading: false,
    error: null,
    isInvalidated: true,
  },
  filters: {
    gender: {
      label: 'Gender',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
      ],
      isDynamic: false,
      providers: true,
    },
    languages: {
      label: 'Languages Spoken',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    groupAffiliations: {
      label: 'Group Affiliations',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    hospitalAffiliations: {
      label: 'Hospital Affiliations',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    specialties: {
      label: 'Specialties',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: false,
      options: [],
      isDynamic: true,
      providers: true,
    },
    acceptingNewPatients: {
      label: 'Accepting New Patients',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: false,
      providers: true,
    },
    // All filters under this line are disabled by default
    // They get enabled through the updateFromClientConfig action that is dispatched in useInitializeFilters
    featured: {
      label: 'Featured', // placeholder label until client config update
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    featuredFacility: {
      label: 'Featured', // placeholder label until client config update
      value: false,
      type: BOOL_FILTER_TYPE,
      places: true,
      disabled: true,
    },
    credentials: {
      label: 'Available Credentials',
      value: [],
      type: ARRAY_FILTER_TYPE,
      disabled: true,
      options: [],
      isDynamic: true,
      providers: true,
    },
    outcareCompetent: {
      label: 'LGBTQ+ Competent',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    highPerforming: {
      label: 'Highly Rated',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    inNetworkPreferred: {
      label: 'In Network Preferred',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    hasBenefitDiff: {
      label: '$0 Copay',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    healthConnectPlan: {
      label: 'Health Connect Plan',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    telehealthAvailable: {
      label: 'Telehealth Available',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    boardCertified: {
      label: 'Board Certified',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    isWheelchairAccessible: {
      label: 'Handicap Accessible',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
    // filter keys prefixed with 'exclude' will affect the 'showExclusions' selector in selectFilters
    excludeClientFeatured: {
      label: 'Exclude Client Featured',
      value: false,
      type: BOOL_FILTER_TYPE,
      disabled: true,
      providers: true,
    },
  },
  sort: {
    provider: DEFAULT_PROVIDER_SORT,
    // This is the most reliable way to accurately set the default place sort at this time, but this will need to be updated as soon as
    // we can load the client config before the app loads. We can then use the client config FEATURED_FACILITY_BANNER_TEXT field to determine this value.
    place: DEFAULT_PLACE_SORT,
  },
  predictedResults: {
    count: null,
    isLoading: false,
    error: null,
  },

  baseParamPredictedResults: 0,

  radius: null, // will be set to client default by `updateFromClientConfig`
};

const filtersSlice = createSlice({
  name: FILTERS_SLICE_NAME,
  initialState,
  reducers: {
    handleFilterChange(state, action) {
      const { key, value: newValue } = action.payload;

      // find the filter to change
      const filterType = state.filters[key]?.type || undefined;

      switch (filterType) {
        case 'undefined':
          // filter does not exist in state
          logDevMessage(`Cannot find filter ${key}`);
          break;
        case ARRAY_FILTER_TYPE:
          // ensure that new value is a string
          if (typeof newValue !== 'string') {
            logDevMessage(
              `Cannot add value ${newValue} of type ${typeof newValue} to ${ARRAY_FILTER_TYPE} filter`
            );
            break;
          }

          // if current array includes the string, remove it. If not, add it
          if (state.filters[key].value.includes(newValue)) {
            state.filters[key].value = state.filters[key].value.filter((str) => str !== newValue);
          } else {
            state.filters[key].value.push(newValue);
          }

          break;
        case BOOL_FILTER_TYPE:
          // ensure that new value is boolean
          if (typeof newValue !== 'boolean') {
            logDevMessage(
              `Cannot add value ${newValue} of type ${typeof newValue} to ${BOOL_FILTER_TYPE} filter`
            );
            break;
          }

          state.filters[key].value = newValue;
          break;
        default:
          // should never reach here unless ney type is added and not handled
          logDevMessage(`Unhandled filter change for ${key}`);
          break;
      }
    },
    clear(state) {
      // clear is for handling the `Clear Filters` button, NOT for start over
      const { filters } = state;

      for (const [key, filter] of Object.entries(filters)) {
        if (filter.type === BOOL_FILTER_TYPE) {
          filters[key].value = false;
        } else if (filter.type === ARRAY_FILTER_TYPE) {
          filters[key].value = [];
        }
      }
    },
    updateFromResults(state, action) {
      // Example payload { filters: { acceptingNewPatients: true, languages: ['Spanish', 'French'], ...etc }, radius: 25 } }
      const { filters, radius } = action.payload;

      state.radius = radius;

      for (const filterKey in state.filters) {
        if (filters[filterKey] !== undefined) {
          state.filters[filterKey].value = filters[filterKey];
        } else {
          // if filterKey does not exist in filters, we should reset it's value
          // this way, the UI will never show a filter as enabled when it has not been applied
          state.filters[filterKey].value = initialState.filters[filterKey].value;
        }
      }
    },
    updateFromClientConfig(state, action) {
      const { filters = {}, radius } = action.payload;

      state.radius = radius;

      // iterate over enabled to enable certain filters
      for (const [filterKey, filterOverrides] of Object.entries(filters)) {
        if (state.filters[filterKey]) {
          // if filter key exists, set disabled. Disabled is the opposite of the filtersToEnable value
          state.filters[filterKey] = { ...state.filters[filterKey], ...filterOverrides };
        } else {
          logDevMessage(`Filter ${filterKey} does not exist`);
        }
      }
    },
    setProviderSort(state, action) {
      const newValue = action.payload;
      state.sort.provider = newValue;
    },
    setPlaceSort(state, action) {
      const newValue = action.payload;
      state.sort.place = newValue;
    },
    invalidateOptions(state) {
      state.options.isInvalidated = true;
    },

    setFilterRadius(state, action) {
      const newValue = action.payload;
      state.radius = newValue;
      state.options.isInvalidated = true;
    },
  },
  extraReducers(builder) {
    // filter counts
    builder
      .addCase(fetchPredictedResultCount.pending, (state) => {
        state.predictedResults.isLoading = true;
        state.predictedResults.error = null;
      })
      .addCase(fetchPredictedResultCount.fulfilled, (state, action) => {
        state.predictedResults.isLoading = false;
        state.predictedResults.error = null;

        const { resultCount } = action.payload;
        const { stripQueryParams } = action.meta.arg || {};

        if (stripQueryParams) {
          state.baseParamPredictedResults = resultCount;
        } else {
          state.predictedResults.count = resultCount;
        }
      })
      .addCase(fetchPredictedResultCount.rejected, (state, action) => {
        state.predictedResults.isLoading = false;
        state.predictedResults.count = null;
        state.predictedResults.error = action.error.message || 'Failed to get result count';
      });

    // start over
    builder.addCase(startOver, (state) => {
      const { filters } = state;

      for (const [key, filter] of Object.entries(filters)) {
        if (filter.type === BOOL_FILTER_TYPE) {
          filters[key].value = false;
        } else if (filter.type === ARRAY_FILTER_TYPE) {
          filters[key].value = [];
        }
      }
      state.sort = initialState.sort;
      state.radius = DEFAULT_RADIUS;
    });

    // filter options
    builder
      .addCase(fetchFilterOptions.pending, (state) => {
        state.options.isLoading = true;
        state.options.error = null;
      })
      .addCase(fetchFilterOptions.fulfilled, (state, action) => {
        state.options.isLoading = false;
        state.options.error = null;
        state.options.isInvalidated = false;
        const filterOptionsObject = action.payload;

        // iterate over filter options
        for (const filterKey in filterOptionsObject) {
          if (state.filters[filterKey] && state.filters[filterKey].isDynamic) {
            // set options for this filter
            state.filters[filterKey].options = filterOptionsObject[filterKey];

            // clear any values in the filter that are not a valid option
            const validValues = filterOptionsObject[filterKey].map((opt) => opt.value);
            state.filters[filterKey].value = state.filters[filterKey].value.filter((val) =>
              validValues.includes(val)
            );
          }
        }
      })
      .addCase(fetchFilterOptions.rejected, (state, action) => {
        state.options.isLoading = false;
        state.options.isInvalidated = true;
        state.options.error = action.error.message || 'An error occurred';
      });

    // url direct search
    builder.addCase(updateStoreFromUrl, (state, action) => {
      /* eslint-disable camelcase */
      const { radius, ordering, care_category, ...urlParams } = action.payload;

      // validate radius value and set
      if (radius && VALID_RADIUS_VALUES.includes(radius)) state.radius = radius;

      if (ordering) {
        // when we receive an ordering param we need to determine if this is a place sort or provider sort
        const isPlaceSearch =
          care_category === CARE_CATEGORIES.FACILITY_NAME ||
          care_category === CARE_CATEGORIES.FACILITY_TYPE ||
          care_category === CARE_CATEGORIES.FACILITY_SERVICE;

        if (isValidOrderingParam(ordering, isPlaceSearch)) {
          if (isPlaceSearch) state.sort.place = ordering;
          else state.sort.provider = ordering;
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isPlaceSearch) state.sort.place = DEFAULT_PLACE_SORT;
          else state.sort.provider = DEFAULT_PROVIDER_SORT;
        }
      }

      // dynamically gather the filter values sent from the url, and update the filter to match
      for (const filterKey in initialState.filters) {
        if (action.payload[filterKey]) {
          state.filters[filterKey].value = urlParams[filterKey];
        } else {
          // if no query param was received for this filter, it should be set to it's initial value
          state.filters[filterKey].value = initialState.filters[filterKey].value;
        }
      }
    });

    // chat
    builder.addCase(chatActions.specialtySearchInPg, (state, action) => {
      const { gender, lgbtqCompetent, radius, languagesSpoken } = action.payload;

      if (gender) {
        state.filters.gender.value = [gender];
      }

      state.filters.outcareCompetent.value = Boolean(lgbtqCompetent);

      if (Array.isArray(languagesSpoken)) {
        state.filters.languages.value = languagesSpoken;
      }

      if (VALID_RADIUS_VALUES.includes(radius)) {
        state.radius = radius; // if the chat radius passed is valid, use it
      } else {
        // when the chat radius is not a valid value, round it up to the nearest valid value OR use the max valid value
        state.radius = findSmallestRadius(radius) || MAX_RADIUS_VALUE;
      }
    });
  },
});

export default filtersSlice;
export const {
  handleFilterChange,
  clear,
  updateFromResults,
  updateFromClientConfig,
  setProviderSort,
  setPlaceSort,
  setFilterRadius,
  invalidateOptions,
} = filtersSlice.actions;
