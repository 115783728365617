import { ENSEMBLE_PROVIDER_LOOKUP, ENSEMBLE_FORM } from 'utils/constants';
import { selectToken } from 'utils/selectToken';
import ensembleConfig from './ensembleConfig';

const apiTokens = {
  dev: 'Token ea0cf433992a401f3046984b45fd229ee8d90f4e',
  staging: 'Token fa3c270d74500943b2db0de25b309bb6265ca45a',
  prod: 'Token b06018a0bb7aa813dedddb071db7db5cda58c48a',
};

const token = selectToken(apiTokens);

const ensembleProviderLookupConfig = {
  // copy base level ensemble config
  ...ensembleConfig,
  // config name used for fetching client config from fusion
  CONFIG_NAME: 'ensemblehp-providerlookup',

  FORM_VARIANT: ENSEMBLE_FORM,
  SHOW_REGION_SELECTOR: false,

  API_TOKEN: token,
  EMBOLD_DATA_TENANT: ENSEMBLE_PROVIDER_LOOKUP,
  EMBOLD_CLIENT: ENSEMBLE_PROVIDER_LOOKUP,
  EMBOLD_CLIENT_SLUG: ENSEMBLE_PROVIDER_LOOKUP,

  // toggles
  CLOSED_ACCESS: false,

  // other
  LOGIN_NETWORK_NAME: 'ensembleProviderLookupUserId',

  // content
  WELCOME_MESSAGE:
    'Enter your home zip code and select a 2025 network option from the Network dropdown menu. Click Continue to go to the Provider Finder tool to search for specific physicians and facilities in that network or review the full network for available options in your area. Once complete, return to this page and repeat your search under the other network option.',
};

export default ensembleProviderLookupConfig;
