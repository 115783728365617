/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Link } from '@material-ui/core';
import { actions, select, constants } from 'store/toolkit';
import {
  COST_EFFICIENCY_SECTION,
  SUBSPECIALTY_SECTION,
  STRENGTHS_SECTION,
  SERVICE_COST_CONTENT,
  PRIMARY_AFFILIATION_METRIC,
} from 'utils/constants';
import DynamicFocusAreaText from 'components/ModernExperience/DynamicFocusAreaText';
import { AFFILIATION_TYPES } from 'store/slices/results/providerConstants';
import Place from 'icons/Place';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles(() => ({
  capitalize: { textTransform: 'capitalize' },
}));

/** Provider metrics are measurable data points of a provider object that are represented by this object
 * @typedef {object} ProviderMetric
 * @property {string} key A unique string identifier for the metric
 * @property {string} icon The URI of the icon displayed for this metric
 * @property {string} title The title or label of this metric
 * @property {string} text A text representation of the metric value.
 * @property {node} action Optional node to render in top right of metric
 * @property {string} className Optional className string
 */

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useCostEfficiencyMetric(provider) {
  const showCost = useSelector(select.featureFlags.showCost);
  const costText = useSelector(select.provider(provider.entityId).costText);

  return useMemo(() => {
    if (!showCost || !costText) return null;

    return {
      key: 'costEfficiency',
      icon: <COST_EFFICIENCY_SECTION.IconComponent />,
      title: COST_EFFICIENCY_SECTION.title,
      text: costText,
      description: COST_EFFICIENCY_SECTION.description,
    };
  }, [showCost, costText]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useFocusAreasMetric(provider) {
  const showSubspecialties = useSelector(select.featureFlags.showSubspecialties);
  const subspecialties = useSelector(select.provider(provider.entityId).sortedSubspecialties);
  const searchedSubspecialtyId = useSelector(select.results.subspecialtyId);
  const dispatch = useDispatch();

  return useMemo(() => {
    if (!showSubspecialties) return null;
    if (!subspecialties.length) return null;

    const subspecialtyNodes = subspecialties.map((ss, i) => (
      <React.Fragment key={`focus-area-${ss.subspecialtyId}-${provider.entityId}`}>
        <DynamicFocusAreaText
          subspecialty={ss}
          key={ss.subspecialtyName}
          isActive={ss.subspecialtyId === searchedSubspecialtyId}
        />
        {i < subspecialties.length - 1 && ', '}
      </React.Fragment>
    ));

    const handleClick = () => {
      dispatch(
        actions.ui.openProfileModal({
          type: constants.ui.PROFILE_MODAL_TYPES.FOCUS_AREAS,
          data: { id: provider.entityId },
        })
      );
    };

    const action = (
      <Link
        component="button"
        onClick={handleClick}
        aria-label={`See more ${SUBSPECIALTY_SECTION.title}`}
      >
        See More &gt;
      </Link>
    );

    return {
      key: 'focusAreas',
      action,
      icon: <SUBSPECIALTY_SECTION.IconComponent />,
      title: SUBSPECIALTY_SECTION.title,
      text: subspecialtyNodes,
      description: SUBSPECIALTY_SECTION.description,
    };
  }, [subspecialties, showSubspecialties, provider, searchedSubspecialtyId, dispatch]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function usePrimaryAffiliationMetric({ entityId }) {
  const dispatch = useDispatch();
  const { primaryAffiliation, groupAffiliations, hospitalAffiliations } = useSelector(
    select.provider(entityId).sortedAffiliations
  );

  return useMemo(() => {
    if (!primaryAffiliation || !primaryAffiliation.type) return null;
    const Icon = primaryAffiliation.type === AFFILIATION_TYPES.HOSPITAL ? Place : GroupIcon;

    const handleClick = () => {
      dispatch(
        actions.ui.openProfileModal({
          type: constants.ui.PROFILE_MODAL_TYPES.AFFILIATIONS,
          data: { id: entityId },
        })
      );
    };

    const additionalAffiliationCount = groupAffiliations.length + hospitalAffiliations.length - 1;

    const action = additionalAffiliationCount > 0 && (
      <Link component="button" onClick={handleClick}>
        See {additionalAffiliationCount} more affiliation{additionalAffiliationCount > 1 && 's'}{' '}
        &gt;
      </Link>
    );

    return {
      key: 'primaryAffiliation',
      action,
      icon: <Icon />,
      text: primaryAffiliation.label,
      title: PRIMARY_AFFILIATION_METRIC.title,
      description: PRIMARY_AFFILIATION_METRIC.description,
    };
  }, [
    dispatch,
    entityId,
    groupAffiliations.length,
    hospitalAffiliations.length,
    primaryAffiliation,
  ]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
export function useStrengthsMetric(provider) {
  const showProviderStrengths = useSelector(select.featureFlags.showProviderStrengths);
  const strengths = useSelector(select.provider(provider.entityId).providerStrengths);

  return useMemo(() => {
    if (!showProviderStrengths) return null;
    if (!strengths.length) return null; // return null if there are no strengths

    return {
      key: 'strengths',
      icon: <STRENGTHS_SECTION.IconComponent />,
      title: STRENGTHS_SECTION.title,
      text: strengths.map((strength) => strength.label).join(', '),
      description: STRENGTHS_SECTION.description,
    };
  }, [strengths, showProviderStrengths]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric|null}
 */
function useServiceCostMetric(provider) {
  const dispatch = useDispatch();
  const service = useSelector(select.services.current);
  const showServiceCost = useSelector(select.featureFlags.showServiceCost);

  const classes = useStyles();

  return useMemo(() => {
    if (!showServiceCost) return null;

    const handleClick = () => {
      dispatch(
        actions.ui.openProfileModal({
          type: constants.ui.PROFILE_MODAL_TYPES.SERVICE_COST,
          data: { id: provider.entityId },
        })
      );
    };

    const actionButton = (
      <Link
        component="button"
        onClick={handleClick}
        aria-label={`See estimate for ${service?.serviceName || 'service'} cost`}
      >
        {SERVICE_COST_CONTENT.buttonText}
      </Link>
    );

    const title = service ? `${service.serviceName} Cost` : 'Service Costs';

    return {
      key: 'serviceCost',
      icon: <SERVICE_COST_CONTENT.IconComponent />,
      title: `${title}:`,
      text: SERVICE_COST_CONTENT.description,
      action: actionButton,
      className: classes.capitalize,
    };
  }, [classes.capitalize, dispatch, provider.entityId, service, showServiceCost]);
}

/**
 *
 * @param {object} provider A full provider object
 * @returns {ProviderMetric[]}
 */
export function useMetricsList(provider) {
  const costEfficiency = useCostEfficiencyMetric(provider);
  const focusAreas = useFocusAreasMetric(provider);
  const primaryAffiliation = usePrimaryAffiliationMetric(provider);
  const strengths = useStrengthsMetric(provider);
  const serviceCost = useServiceCostMetric(provider);

  return useMemo(() => {
    const metricList = [];

    if (focusAreas) metricList.push(focusAreas);
    if (strengths) metricList.push(strengths);
    if (primaryAffiliation) metricList.push(primaryAffiliation);
    if (costEfficiency) metricList.push(costEfficiency);
    if (serviceCost) metricList.push(serviceCost);
    return metricList;
  }, [costEfficiency, focusAreas, primaryAffiliation, serviceCost, strengths]);
}
