/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { select, actions } from 'store/toolkit';
import Modal from 'components/Modals/Modal';
import CopyTextButton from 'components/CopyTextButton';

const clients = [
  'walmart',
  'walmart-national',
  'microsoft',
  'polk',
  'demo',
  'customerdemo',
  'embold',
  'icuba',
  'bcbst-support',
  'bcbst',
  'emsanacare',
  'surgeryplus',
  'bnsf',
  'jbhunt',
  'carecompass',
  'carecompass-providerlookup',
  'ensemblehp',
  'ensemblehp-providerlookup',
  'continental',
  'quantum',
  'feature-three',
  'feature-two',
  'feature-one',
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function ClientConfigDevToolsModal() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedClientConfig, setSelectedClientConfig] = useState('');
  const [urlToken, setURLToken] = useState('');
  const [apiURL, setApiUrl] = useState('');
  const [apiToken, setApiToken] = useState('');
  const [bearerTokenURL, setBearerTokenURL] = useState('');
  const [djangoAuthToken, setDjangoAuthToken] = useState('');
  const [apiRequestStatus, setAPIrequestStatus] = useState('');

  const ClientConfigDevToolsModalOpen = useSelector(select.ui.ClientConfigDevToolsModalOpen);
  const localClientToUse = useSelector(select.config.localClientToUse);
  const configApiURL = useSelector(select.config.apiUrl);
  const djangoURL = useSelector(select.config.apiUrl);
  const currentNetworkSlug = useSelector(select.networks.currentSlug);

  useEffect(() => {
    const djangoToken = localStorage.getItem('djangoToken');
    if (djangoToken) {
      setDjangoAuthToken(djangoToken);
    }
  }, []);

  const closeDevTools = useCallback(() => {
    dispatch(actions.ui.closeModal());
  }, [dispatch]);

  const handleClientChange = useCallback((e) => {
    setSelectedClientConfig(e.target.value);
  }, []);

  const handleUrlTokenChange = useCallback((e) => {
    setURLToken(e.target.value);
  }, []);

  const handleFusionTokenChange = useCallback((e) => {
    setDjangoAuthToken(e.target.value);
  }, []);

  const handleAPIUrlChange = useCallback((e) => {
    setApiUrl(e.target.value);
  }, []);

  const handleAPITokenChange = useCallback((e) => {
    setApiToken(e.target.value);
  }, []);

  const handleSaveButtonClick = useCallback(
    (e) => {
      dispatch(
        actions.config.setLocalDevClientConfig({
          selectedClientConfig: selectedClientConfig,
          urlToken: urlToken,
          apiURL: apiURL,
          apiToken: apiToken,
        })
      );
    },
    [selectedClientConfig, urlToken, apiURL, apiToken, dispatch]
  );

  const generateURLtoken = useCallback(() => {
    const myHeaders = new Headers();

    const raw = JSON.stringify({
      network_slug: currentNetworkSlug,
      member_id: '123',
    });

    myHeaders.set('Content-Type', 'application/json');
    myHeaders.set('Authorization', `Token ${djangoAuthToken}`);

    fetch(`${djangoURL}/token/`, {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    })
      .then((response) => response.text())
      .then((result) => {
        result = JSON.parse(result);
        const url = new URL(result.location);
        const token = url.searchParams.get('token');
        setURLToken(token);
        setBearerTokenURL(url);
        setAPIrequestStatus('Success');
        localStorage.setItem('djangoToken', djangoAuthToken);
      })
      .catch((error) => {
        setAPIrequestStatus(error.toString());
        console.log('error', error);
      });
  }, [djangoURL, djangoAuthToken, currentNetworkSlug]);

  const handleClearButtonClick = useCallback(
    (e) => {
      dispatch(actions.config.clearLocalDevConfig());
    },
    [dispatch]
  );

  return (
    <Modal
      open={ClientConfigDevToolsModalOpen}
      handleClose={closeDevTools}
      ariaId="dev-tools-modal"
      title="Change Client Configuration"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          padding: 16,
          gap: 16,
        }}
      >
        <Typography variant="h3">Client Config</Typography>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">Client Config</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleClientChange}
            value={selectedClientConfig}
          >
            {clients.sort().map((client, i) => (
              <MenuItem key={client} value={client}>
                {client}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Current Value In State: {localClientToUse}</FormHelperText>
          <TextField
            id="token-config"
            label="Closed Access Token"
            helperText="integration-token ex. 'xxxxxxxxxxx' for use in closed access clients; '?token=' is prepended"
            value={urlToken}
            onChange={handleUrlTokenChange}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="token-config"
            label="Personal Fusion Auth Token"
            helperText="Fusion User client token must match your personal account to avoid 403 error"
            value={djangoAuthToken}
            onChange={handleFusionTokenChange}
          />
          <FormHelperText>
            <strong>Current Token Origin: {bearerTokenURL.toString() || 'none'}</strong>
          </FormHelperText>

          <FormHelperText>
            <strong>
              Status:{' '}
              <span
                style={{
                  color: apiRequestStatus === 'Success' ? 'green' : 'red',
                }}
              >
                {apiRequestStatus}
              </span>
            </strong>
          </FormHelperText>
        </FormControl>
        <Button variant="outlined" color="primary" onClick={generateURLtoken}>
          Generate closed access token
        </Button>
        <Typography variant="h3">Fusion Config</Typography>
        <TextField
          id="api-url-config"
          label="API URL"
          helperText={
            <>
              <CopyTextButton
                textToCopy="https://localhost:10000/api/pg"
                buttonText="ex. 'https://localhost:10000/api/pg'"
              />
              <br />
              Current value in state: {configApiURL}
            </>
          }
          value={apiURL}
          onChange={handleAPIUrlChange}
        />
        <TextField
          id="api-token-config"
          label="API TOKEN (FOR LOCAL API USE)"
          helperText="ex. 'Token xxxxxxxxxxxxxxx'"
          value={apiToken}
          onChange={handleAPITokenChange}
        />
        <Button variant="contained" color="primary" onClick={handleSaveButtonClick}>
          Save Changes
        </Button>
        <Button style={{ backgroundColor: 'red', color: 'white' }} onClick={handleClearButtonClick}>
          Clear Config Changes (reset to walmart config)
        </Button>
      </div>
    </Modal>
  );
}

export default ClientConfigDevToolsModal;
