/* eslint-disable import/prefer-default-export */
import { isEmpty } from 'lodash';
import { distance } from 'utils/utils';
import { selectProviderAnalyticsAttributes } from './selectAnalytics';

export const getProviderAttributes = (providerData) => {
  if (!providerData || isEmpty(providerData)) return {};
  const {
    specialty,
    npi,
    entityName,
    scoreOverallQuality,
    scoreCareJourneyDiagnosing,
    scoreCareJourneyOutcomes,
    scoreCareJourneyTreatment,
    scoreTier,
  } = providerData;

  return {
    specialty,
    npi,
    entityName,
    scoreOverallQuality,
    scoreCareJourneyDiagnosing,
    scoreCareJourneyOutcomes,
    scoreCareJourneyTreatment,
    scoreTier,
  };
};

export const getChatProviderAttributes = ({ coordinates, providerData }) => {
  if (!providerData || isEmpty(providerData)) return {};
  const baseAttributes = getProviderAttributes(providerData);
  const { latitude, longitude } = providerData.closestVisibleLocation;
  const distanceToClosestLocation = distance(
    latitude,
    longitude,
    coordinates?.latitude,
    coordinates?.longitude
  );

  return {
    ...baseAttributes,
    distance_to_result: distanceToClosestLocation || 'N/A',
  };
};

export const getEncodedTreatmentCategories = (services) => {
  if (!Array.isArray(services)) return {};
  const res = {};
  services
    .map(({ treatmentCategories }) => treatmentCategories)
    .flat(1)
    .forEach((tc) => {
      let encodedTc;
      let code;
      try {
        const { memberCost, planCost, treatmentCategoryCode } = tc;
        code = treatmentCategoryCode;
        encodedTc = new URLSearchParams({
          memberCost,
          planCost,
        });
      } catch {
        encodedTc = null;
      }
      if (encodedTc && code) res[code] = encodedTc.toString();
    });
  return res;
};

export const getCostEventData = (state, { payload }) => {
  const { npi, locationId, view, serviceId, data, userHasMetOutOfPocketMaximum, isError } = payload;
  const resultAttributes = selectProviderAnalyticsAttributes(npi)(state);
  const treatmentCategories = getEncodedTreatmentCategories(data);
  let costDataStatus;
  if (Object.keys(treatmentCategories).length) {
    costDataStatus = 'ok';
  } else if (isError) {
    costDataStatus = 'error';
  } else {
    costDataStatus = 'no results';
  }

  return {
    ...resultAttributes,
    locationId,
    serviceId,
    ...treatmentCategories,
    costDataStatus,
    userHasMetOutOfPocketMaximum,
    eventAction: `Provider Service Cost ${view} View`,
    eventLabel: 'Provider Service Cost View',
  };
};
