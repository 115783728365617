import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { select, actions } from 'store/toolkit';

export default function useEnsembleNetworksOptionsByZipcode(zipcode) {
  const axios = useSelector(select.axios.axiosInstance);
  const dispatch = useDispatch();

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (zipcode?.length === 5) {
      setIsLoading(true);
      setOptions([]);
      axios
        .get(`networks/?limit=2&zipcode=${zipcode}`)
        .then((res) => setOptions(res.data))
        .catch((e) => {
          setError(e);
          dispatch(
            actions.notifications.create({
              message:
                'There was an error getting your available networks. Please reenter your ZIP or refresh the page.',
              severity: 'error',
            })
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [axios, zipcode, dispatch]);

  return { options, isLoading, error };
}
