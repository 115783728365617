import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import MultiAutocompleteModal from 'components/ModernExperience/Search/Autocomplete/MultiAutocompleteModal';
import { MULTI_SEARCH_PLACEHOLDER_TEXT } from 'components/ModernExperience/Search/Autocomplete/MultiAutocomplete';
import { select, actions } from 'store/toolkit';
import PropTypes from 'propTypes/index';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    [`& fieldset`]: {
      borderColor: theme.palette.grey[300],
    },
  },
  startIcon: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[700],
    height: 22,
  },
  input: {
    fontSize: '1rem',
    '& ::placeholder': {
      opacity: 1,
      color: theme.palette.grey[600],
      fontSize: '.875rem',
    },
  },
}));

function MultiAutocompleteDummy({ submitSearch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchInput = useSelector(select.search.text);

  return (
    <>
      <TextField
        id="provider-search-dummy"
        placeholder={MULTI_SEARCH_PLACEHOLDER_TEXT}
        value={searchInput}
        variant="outlined"
        margin="dense"
        onClick={() => dispatch(actions.ui.openModal('providerSearch'))}
        onChange={({ target: { value } }) => {
          dispatch(actions.ui.openModal('providerSearch'));
          dispatch(actions.search.handleTextInput({ value, reason: 'input' }));
        }}
        InputProps={{
          classes: { root: classes.input },
          startAdornment: (
            <InputAdornment
              position="start"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SearchIcon className={classes.startIcon} />
            </InputAdornment>
          ),
        }}
        className={classes.textField}
      />

      <MultiAutocompleteModal submitSearch={submitSearch} />
    </>
  );
}

MultiAutocompleteDummy.propTypes = {
  submitSearch: PropTypes.func.isRequired,
};

export default MultiAutocompleteDummy;
